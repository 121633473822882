"use strict";

import {trackEvent} from "@elements/tracking/src/gtm";
import {getInputState} from "@elements/product-grid";

let changedFilters = [];

export function init() {
    let $productGrid = $('.js-product-grid');

    // desktop: track always when the product grid has changed
    if (matchMedia('(min-width: 768px)').matches) {
        $productGrid.on('product-grid.changed', function (evt, activeFilters) {
            trackChangedFilters(activeFilters);
        });
    }
}

export function initInScope($scope) {

    $scope.find(
        '.js-product-grid__input, ' +
        '.js-product-grid__form input, ' +
        '.js-product-grid__form select'
    ).on('change', function () {
        changedFilters.push($(this).attr('name'));
    });

    // mobile: track after closing the filter overlay
    if (!matchMedia('(min-width: 768px)').matches) {
        $scope.find('.js-track-filter-changes').on('click', function () {
            trackChangedFilters(getInputState($('.js-product-grid')));
        });
    }

}

function trackChangedFilters(activeFilters) {
    let alreadyTrackedFilters = [];

    changedFilters.forEach(filter => {
        let currentFilterName = filter.split('[')[0];
        if (!alreadyTrackedFilters.includes(currentFilterName)) {


            let filtersToTrack = activeFilters.filter(activeFilter => {
                if (activeFilter.hidden && activeFilter.name !== 'sort') {
                    return false;
                }

                // check if the filter name is the same - but if it contains '[' only use the part before it
                return activeFilter.name.split('[')[0] === currentFilterName;
            });

            let filterValuesToTrack = filtersToTrack.map(item => item.value).join(',');

            trackEvent(
                {category: 'Katalogfilter', action: currentFilterName, label: filterValuesToTrack},
                {debug: true},
            );

            alreadyTrackedFilters.push(currentFilterName);
        }
    });

    changedFilters = [];
}
