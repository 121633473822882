"use strict";

let isRunning = false;
let $stickyBar;
let $watchElement;
let viewportHeight = window.innerHeight;

export function init() {
    $stickyBar = $('.js-grid-footer');
    let watchElementId = $stickyBar.data('watch-element');

    if (!watchElementId) {
        console.error('"data-watch-element" is not defined for js-grid-footer');
        return;
    }

    $watchElement = $('#' + watchElementId);

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

let lastScrollPosition = 0;
export function interval() {
    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;
    //console.log(viewportHeight);
    //console.log($watchElement[0].getBoundingClientRect().bottom);

    if ($watchElement[0].getBoundingClientRect().bottom <= viewportHeight) {
        $stickyBar.addClass('grid-footer--show');
    } else {
        $stickyBar.removeClass('grid-footer--show');
    }
    requestAnimationFrame(interval);
}