'use strict'

export function initInScope() {
    function getTimeRemaining(endtime) {

        let total = endtime - Date.now();
        let seconds = Math.floor((total / 1000) % 60);
        let minutes = Math.floor((total / 1000 / 60) % 60);
        let hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        let days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    }

    if($('#js-countdown').length) {
        function initializeClock(id, endtime) {
            let clock = document.getElementById(id);
            if (!clock) {
                return;
            }
            let daysSpan = clock.querySelector('.js-countdown__days');
            let hoursSpan = clock.querySelector('.js-countdown__hours');
            let minutesSpan = clock.querySelector('.js-countdown__minutes');
            let secondsSpan = clock.querySelector('.js-countdown__seconds');

            let daysTextEl = clock.querySelector('.js-countdown__days-text');
            let hoursTextEl = clock.querySelector('.js-countdown__hours-text');
            let minutesTextEl = clock.querySelector('.js-countdown__minutes-text');
            let secondsTextEl = clock.querySelector('.js-countdown__seconds-text');

            let daysTextSingular = _config.countdownDaysSingular;
            let hoursTextSingular = _config.countdownHoursSingular;
            let minutesTextSingular = _config.countdownMinutesSingular;
            let secondsTextSingular = _config.countdownSecondsSingular;

            let daysTextPlural = _config.countdownDaysPlural;
            let hoursTextPlural = _config.countdownHoursPlural;
            let minutesTextPlural = _config.countdownMinutesPlural;
            let secondsTextPlural = _config.countdownSecondsPlural;

            function updateClock() {
                let t = getTimeRemaining(endtime);

                if(t.days == '1') {
                    daysTextEl.innerHTML = daysTextSingular;
                } else {
                    daysTextEl.innerHTML = daysTextPlural;
                }

                if(t.hours == '1') {
                    hoursTextEl.innerHTML = hoursTextSingular;
                } else {
                    hoursTextEl.innerHTML = hoursTextPlural;
                }

                if(t.minutes == '1') {
                    minutesTextEl.innerHTML = minutesTextSingular;
                } else {
                    minutesTextEl.innerHTML = minutesTextPlural;
                }

                if(t.seconds == '1') {
                    secondsTextEl.innerHTML = secondsTextSingular;
                } else {
                    secondsTextEl.innerHTML = secondsTextPlural;
                }

                daysSpan.innerHTML = t.days;
                hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

                if (t.total <= 0) {
                    clearInterval(timeinterval);
                    daysSpan.innerHTML = "0";
                    hoursSpan.innerHTML ="00";
                    minutesSpan.innerHTML = "00";
                    secondsSpan.innerHTML = "00";
                } else {

                }
            }

            updateClock();
            let timeinterval = setInterval(updateClock, 1000);
        }
        let countdownTimestring = new Date($('#js-countdown').attr('data-timestring'));
        let deadline = new Date(countdownTimestring.getTime() + (countdownTimestring.getTimezoneOffset() + 1 * 60 * 1000));
        initializeClock('js-countdown', deadline);
    }

}
