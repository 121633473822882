"use strict";

export function initInScope ($scope) {
    let $slider     = $scope.find('.js-product-grid-slider'),
        prices      = $slider.data('prices'),
        minPrice    = $slider.data('min'),
        maxPrice    = $slider.data('max'),
        minValue    = parseInt($slider.data('min')),
        maxValue    = parseInt($slider.data('max')),
        currency    = $slider.data('currency'),
        $amount    = $scope.find('.js-slider-amount');

    import('nouislider').then(function (noUiSlider) {
        $slider.each(function (key, value) {
            let $container = $(this);
            let slider = $container.find('.js-product-grid-slider__slider')[0];
            let $minInput = $container.find('.js-product-grid-slider__min');
            let $maxInput = $container.find('.js-product-grid-slider__max');
            let $minText = $container.find('.js-product-grid-slider__min-text');
            let $maxText = $container.find('.js-product-grid-slider__max-text');

            let min = parseInt(minPrice);
            let max = parseInt(maxPrice);

            noUiSlider.create(slider, {
                start: [
                    $minInput.val() || min,
                    $maxInput.val() || max
                ],
                connect: true,
                range: {
                    'min': min,
                    'max': max
                }
            });

            /* product-grid.input-changed or changed to react to changes from the product grid */
            $minInput.on('product-grid.input-changed', function () {
                setMin(this.value !== '' ? Math.floor(this.value) : min);
            });

            /* product-grid.input-changed or changed to react to changes from the product grid */
            $maxInput.on('product-grid.input-changed', function () {
                setMax(this.value !== '' ? Math.floor(this.value) : max);
            });

            slider.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];
                if ( handle ) {
                    setMaxText(parseInt(value));
                } else {
                    setMinText(parseInt(value));
                }

                updateCount();
                
            });

            slider.noUiSlider.on('change', function ( values, handle ) {
                /* trigger change to notify the product-grid*/
                if ( handle ) {
                    setMax(parseInt(values[handle]));
                    $maxInput.trigger('change');
                } else {
                    setMin(parseInt(values[handle]));
                    $minInput.trigger('change');
                }
            });

            function setMin(value) {
                value = +value || min;
                $minInput.val(+value === min ? null: value);
                slider.noUiSlider.set([value, null]);
                setMinText(value);
            }

            function setMinText(value) {
                value = +value || min;
                value = parseInt(value);
                minValue = value;
                let text = value.toLocaleString(_config.local);

                $minText.html(text + '.00&nbsp;' + currency);
                $minInput.data('text', text);
            }

            function setMax(value) {
                value += .99;

                value = +value || max;
                $maxInput.val(+value === max + .99 ? null: value);
                slider.noUiSlider.set([null, value]);
                setMaxText(value);
            }

            function setMaxText(value) {
                value = +value || max;
                value = parseInt(value);
                maxValue = value;
                let text = value.toLocaleString(_config.local);
                $maxText.html(text + '.99&nbsp;' + currency);
                $maxInput.data('text', text);
            }

            function updateCount(){

                let min = parseFloat(minValue),
                    max = parseFloat(maxValue) + 1;

                let amount= 0;
                $.each(prices, function (index, value) {
                    if(parseFloat(value['min']) >= min && parseFloat(value['max']) < max){
                        amount += parseInt(value['count']);
                    }
                });
                $amount.html(amount);
            }

            slider.noUiSlider.on('update', (values) => {
                if (min !== parseFloat(values[0]) || max !== parseFloat(values[1])){
                    $(value).closest('.js-grid-filter-btn').addClass('has-value');
                }else{
                    $(value).closest('.js-grid-filter-btn').removeClass('has-value');
                }
            })
        })
    });
}
