"use strict";

let filterCollapse;

export function initInScope ($scope) {

    initProductFilter();

    function initProductFilter() {
        
        const $filterBtnCollapse = $scope.find('.js-grid-filter-btn__collapse');

        let inputChanged = false;

        $scope.find('.js-product-grid__submit-section').on('change', (e) => {
            inputChanged = true;

            let $submitSection =  $(e.target).closest('.js-product-grid__submit-section');

            if (matchMedia('(max-width: 767px)').matches) {
                $submitSection.find('.js-product-grid__submit-section-btn').trigger('click');
            }
        });

        $filterBtnCollapse.on('show.bs.collapse', (e) => {
            if (matchMedia('(max-width: 767px)').matches) {
                return;
            }

            closeOtherFilters($(e.target).attr('id'), $filterBtnCollapse);
            let $filterBtn = $(e.target).closest('.js-grid-filter-btn');
            if ($filterBtn.closest('.js-grid-filter-btn--container')) {
                $filterBtn.closest('.js-grid-filter-btn--container').css('height', $filterBtn.outerHeight())
            }
            $filterBtn.addClass('open z-index-2');

        });

        $filterBtnCollapse.on('shown.bs.collapse', (e) => {
            if (matchMedia('(max-width: 767px)').matches) {
                return;
            }
            let $filterBtn = $(e.target).closest('.js-grid-filter-btn');
            let $submitBtn = $(e.target).find('.js-product-grid__submit-section-btn');
            $filterBtn.removeClass('z-index-2');

            $('body').on('click', bodyClickHandler);

            // remove body click handler so the click after the product-grid refresh is normal
            $('.js-product-grid').one('product-grid.changed', function () {
                $('body').off('click', bodyClickHandler);
            });

            //scroll to filter button if not in viewport
            let top_of_element = $submitBtn.offset().top;
            let bottom_of_element = $submitBtn.offset().top + $submitBtn.outerHeight();
            let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            let top_of_screen = $(window).scrollTop();
            let scrolling = (top_of_element - bottom_of_screen + 100);

            if ((bottom_of_screen > bottom_of_element) && (top_of_screen < top_of_element)){
                //console.log('no scroll');
            } else {
                //console.log('do scroll');
                $('html, body').animate({scrollTop: '+='+scrolling}, 250);
            }

        });

        $filterBtnCollapse.on('hidden.bs.collapse', (e) => {
            if (matchMedia('(max-width: 767px)').matches) {
                return;
            }

            let $filterBtn = $(e.target).closest('.js-grid-filter-btn');
            $filterBtn.removeClass('open');

            if(inputChanged){
                $filterBtn.find('.js-product-grid__submit-section-btn').trigger('click');
                inputChanged = false;
            }

            // remove body click handler so the click after the product-grid refresh is normal
            $('body').off('click', bodyClickHandler);
        });
    }
    
    function closeOtherFilters($currentFilter, $allFilters) {
        $allFilters.each( function() {

           if($(this).attr('id') !== $currentFilter){
               $(this).collapse('hide');
           }
        });
    }


    if (filterCollapse === true) {
        $('.js-product-grid--expand').collapse('show');
    }

    $scope.find('.js-product-grid--expand').on('hidden.bs.collapse', function () {
        filterCollapse = false;
    });

    $scope.find('.js-product-grid--expand').on('shown.bs.collapse', function () {
       filterCollapse = true;
    });
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.js-grid-filter-btn').length < 1) {
        // evt.preventDefault();

        $('.js-grid-filter-btn__collapse').collapse('hide');
        $('body').off('click', bodyClickHandler);
    }
}