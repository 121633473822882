"use strict";

let isRunning = false;
let $stickyFilterBtn = $('.js-sticky-product-grid-filter-btn');
let $watchElement = $('.js-sticky-product-grid-filter-watch-btn');

export function initInScope() {
    isRunning = true;
    lastScrollPosition = 0;
    $stickyFilterBtn = $('.js-sticky-product-grid-filter-btn');
    $watchElement = $('.js-sticky-product-grid-filter-watch-btn');
    if($stickyFilterBtn.length && $watchElement.length) {
        requestAnimationFrame(interval);
    }
}

let lastScrollPosition = 0;

export function interval() {
    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;
    if ($watchElement[0].getBoundingClientRect().top < 1) {
        $stickyFilterBtn.addClass('is-sticky');
    } else {
        $stickyFilterBtn.removeClass('is-sticky');
    }
    requestAnimationFrame(interval);
}