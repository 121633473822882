import $ from 'jquery';
import './scripts/common';
import onInitModulesInScope from './scripts/onInitModulesInScope';
import initModulesInScope from "@elements/init-modules-in-scope";
import * as productGrid from './libs/@elements/product-grid';
import * as productGridQuickFilter from '@elements/product-grid/quick-filter';
import * as filterTracking from './scripts/filterTracking';
import * as ajaxFormScroll from './scripts/ajaxFormScroll';
import * as ajaxForm from '@elements/ajax-form';
import * as productGridSlider from './scripts/productGridSlider';
import * as productGridFilter from './scripts/productGridFilter';
import * as accordionScrollTo from './scripts/accordionScrollTo';
import * as stickyProductGridFilter from './scripts/stickyProductGridFilter';
import * as productSlider from './scripts/productSlider';
import * as friendlyRecaptcha from './scripts/friendlyRecaptcha';
import * as toggleOffscreen from './scripts/toggle-offscreen';
import * as stepView from './scripts/stepView';
import * as parsley from '@elements/parsley-bootstrap-validation';
import * as slider from '@elements/slider';
import * as smoothScroll from '@elements/smooth-scroll';
import * as gridFooter from './scripts/gridFooter';
import * as countdown from './scripts/countdown';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = window['app'] || {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
let customProductGrid = productGrid.createProductGrid({
    onFetch: response => {
        // if the PG response contains the replaceUrl, the current URL will be replaced by it,
        // so certain filters (e.g. color) can be represented in the URL in a different way (e.g. /damenschuhe/rot?size=37)
        if (response.replaceUrl) {
            history.replaceState(
                history.state,
                document.title,
                response.replaceUrl);
        }
        return response;
    }
});
customProductGrid.init();
onInitModulesInScope(customProductGrid.initInScope);

onInitModulesInScope(productGridQuickFilter.createQuickFilter({},{},{
        defaultTemplate: (data) => `<div class="product-grid__quickfilter-group">${Object.keys(data).map(function (key) {
            return data[key].sizeadvisor ? "<button type='button' class='d-flex align-items-center quickfilter-btn js-product-grid-quick-filter__item is-size-advisor' data-filter-name='"+ data[key].name +"' data-filter-value='"+ data[key].value +"' title='Filter entfernen'><span class='icon icon-size-finder size-advisor__icon'></span> " + data[key].text + "</button>" : 
                "<button type='button' class='d-flex align-items-center quickfilter-btn js-product-grid-quick-filter__item' data-filter-name='"+ data[key].name +"' data-filter-value='"+ data[key].value +"' title='Filter entfernen'>" + data[key].text + "</button>"
        }).join("")}</div>`
    }
).initInScope);

filterTracking.init();
onInitModulesInScope(filterTracking.initInScope);

onInitModulesInScope(ajaxForm.initInScope);

onInitModulesInScope(ajaxFormScroll.initInScope);

onInitModulesInScope(productGridSlider.initInScope);

onInitModulesInScope(productGridFilter.initInScope);

onInitModulesInScope(stickyProductGridFilter.initInScope);

onInitModulesInScope(accordionScrollTo.initInScope);

onInitModulesInScope(productSlider.initInScope);

onInitModulesInScope(friendlyRecaptcha.initInScope);

onInitModulesInScope(toggleOffscreen.initInScope);

onInitModulesInScope(stepView.initInScope);

onInitModulesInScope(countdown.initInScope);

onInitModulesInScope(parsley.initInScope);

onInitModulesInScope(smoothScroll.initInScope);

onInitModulesInScope(gridFooter.init);

let recommendationSliderOptions = {
    prevArrow:"<button type='button' class='slick-prev' aria-label='prev'><span class='icon icon-arrow-left'></span></button>",
    nextArrow:"<button type='button' class='slick-next' aria-label='next'><span class='icon icon-arrow-right'></span></button>",
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4
};

if (matchMedia('(max-width: 541px)').matches) {
    recommendationSliderOptions.slidesToShow = 1.2;
} else if (matchMedia('(max-width: 1199px)').matches) {
    recommendationSliderOptions.slidesToShow = 3;
}

onInitModulesInScope(slider.createInitInScope('.js-recommendation-slider', recommendationSliderOptions));

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);
